// src/components/ImageCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css'; // Import your CSS file

const ImageCarousel = () => {
  return (
    <div className="image-carousel-container">
      <Carousel showThumbs={false} autoPlay infiniteLoop>
        <div>
        <img src="../cuisine.jpg" alt="Grenier remplie de valises" className="carousel-image"/>
        </div>
        <div>
        <img src="../valises.jpg" alt="Grenier remplie de valises" className="carousel-image"/>
        </div>
        <div>
        <img src="../grenier.jpg" alt="Grenier remplie de valises" className="carousel-image"/>
        </div>
        <div>
        <img src="../salon.jpg" alt="Grenier remplie de valises" className="carousel-image"/>
        </div>
      </Carousel>
    </div>
  );
}

export default ImageCarousel;

