import React from 'react';
import './App.css';
import ImageCarousel from './components/ImageCarousel'; // Import the carousel component


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src="/logo-init.png" className="App-logo" alt="logo bruno debarras" />
          <p className='name'>Débarras Bruno</p>
        </div>
        <a href="tel:0609737364" className="phone">Appelez-nous au 06 09 73 73 64</a>
      </header>
      <main>
        <div className="presentation">
          <img src="/logo-full.png" className="logo-full" alt="logo bruno debarras" />
          <br />
          <p>Confiez votre logement à une entreprise familiale et locale, qui a plus de 50 ans d'expérience. </p>
          <p> Nous venons débarrasser votre maison, appartement ou commerce avec le plus grand soin.</p>
          <p>Nous nous déplaçons sur tout le Finistère, et nous vous proposons une estimation gratuite de nos services.</p>
          <br />
        </div>
        <ImageCarousel />
        <div className="services">
          <div className="service">
            <img src="/renumere.png" className="logo-full" alt="logo bruno debarras" />
            <p>Vous avez des biens de valeurs ? Nous les estimons et vous les rachetons. Si la somme dépasse nos frais d'intervention, nous vous versons la différence. Paiement comptant. </p>
          </div>
          <div className="service">
            <img src="/gratuit.png" className="logo-full" alt="logo bruno debarras" />
            <p>Le débarras est gratuit quand la vente de vos biens couvre nos frais d'intervention.</p>
          </div>
          <div className="service">
            <img src="/payant.png" className="logo-full" alt="logo bruno debarras" />
            <p>Le débarras est payant quand la vente de vos biens ne couvre pas nos frais. Nous vous ferons un devis rapide, gratuit et sans engagement. </p>
          </div>
        </div>
        <div className="situations">
          <p className='situations-q'>Pourquoi faire appel à Bruno et ses associés?</p>
          <p className='situations-p'>Nous sommes une entreprise familiale et locale qui intervient rapidement, avec discrétion et expertise. Nous agissons dans diverses situations:</p>
          <ul>
            <li>Succession: vous héritez d'une maison, nous saurons vous aider dans ces moments difficiles afin de faire au mieux et au plus vite.</li>
            <li>Vente ou Déménagement: vous souhaitez vendre ou déménager et désirez l'aide d'un professionel pour tirer le meilleur prix de vos biens. </li>
            <li>Libération d'espace: vous avez des pièces de votre maison à vider, un garage, une cave ou autre.</li>
            <li>Syndrôme de Diogène: vous-même ou un proche souffrez du Syndrôme de Diogène, nous saurons vous aider en toute discrétion.</li>
          </ul>
        </div>
        <div className="processus">
          <p className='situations-q'>Comment se passe notre intervention ?</p>
        <div className="services">
          <div className="service">
            <img src="/number-1.png" className="logo-full" alt="logo bruno debarras" />
            <p>Après votre appel, nous venons rapidement faire une estimation gratuite. Nous vous dirons quel type d'intervention sera nécessaire et nous vous paierons directement dans le cas d'une intervention rénumérée.</p>
          </div>
          <div className="service">
            <img src="/number-2.png" className="logo-full" alt="logo bruno debarras" />
            <p>Quand vous êtes disponible, nous récupérons vos biens: meubles, vaisselle, tableaux, bibelots... Nous nous assurons de laisser les lieux propres. </p>
          </div>
          <div className="service">
            <img src="/number-3.png" className="logo-full" alt="logo bruno debarras" />
            <p>Ce qui a une certaine valeur est revendu à nos partenaires. Nous nous efforçons de donner une deuxième vie aux autres objets, en les donnant aux associations caritatives locales. Le reste sera emmené en déchetterie afin d'être trié et recyclé.   </p>
          </div>
        </div>
        </div>
        <div className="contact-info">
          <p style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '10px', }}>Contactez-nous !</p>
          <p><b>Adresse:</b> 44, rue Lamartine 29490 Guipavas</p>
          <a href="tel:0609737364"><b>Téléphone:</b> 06 09 73 73 64</a>
          <p><b>Horaires:</b> Du lundi au samedi, de 8h à 17h.</p> <p>Nous sommes flexibles et savons nous rendre disponibles.</p>
          <p style={{marginBottom: '20px', }}><b>Zone d'intervention:</b> Brest et tout le Finistère</p>
        </div>
      </main>
      <footer>
      </footer>

    </div>
  );
}

export default App;
